import styled from 'styled-components';

import { breakpoints } from './utils';

export const Wrapper = styled.div`
  max-width: 1300px;
  margin: auto;
  padding: 0 100px;
  ${breakpoints('padding', [
    { 1200: '0 80px' },
    { 1000: '0 40px' },
    { 600: '0 20px' },
  ])};
`;

export default Wrapper;
