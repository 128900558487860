import React from 'react';
import Helmet from 'react-helmet';
import featuresmall from '@/assets/featuresmall.png';

const description = 'Talk to your future self, loved ones and friends';
const title = 'Capsule';

const Meta = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
    <meta name="twitter:description" content={description} />

    <meta name="image" content={featuresmall} />
    <meta property="og:image" content={featuresmall} />
    <meta property="twitter:image" content={featuresmall} />

    <title>{title}</title>
    <meta property="og:title" content={title} />
    <meta name="twitter:title" content={title} />

    <link rel="canonical" href="http://capsulechat.com" />
  </Helmet>
);

export default Meta;
