import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import CapsuleLogo from '@/graphics/CapsuleLogo';
import Wrapper from './Wrapper';
import Meta from './MetaHeader';

const HeaderDiv = styled.div`
  display: flex;
  padding-top: 30px;
`;

const Header = () => (
  <Wrapper>
    <Meta />
    <HeaderDiv>
      <Link to="/">
        <CapsuleLogo width={200} />
      </Link>
    </HeaderDiv>
  </Wrapper>
);

export default Header;
